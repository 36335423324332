(function (doc, win) {
    var docEl = doc.documentElement,
        isIOS = navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        dpr = isIOS ? Math.min(win.devicePixelRatio, 3) : 1,
        dpr = window.top === window.self ? dpr : 1, //被iframe引用时，禁止缩放
        dpr = 1,
        scale = 1 / dpr,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
    docEl.dataset.dpr = dpr;
    var metaEl = doc.createElement('meta');
    metaEl.name = 'viewport';
    metaEl.content = 'initial-scale=' + scale + ',maximum-scale=' + scale + ', minimum-scale=' + scale;
    docEl.firstElementChild.appendChild(metaEl);
    var recalc = function () {
        var width = docEl.clientWidth;
        if (width / dpr > 750) {
            width = 750 * dpr;
        }
        // 乘以100，px : rem = 100 : 1
        docEl.style.fontSize = 100 * (width / 750) + 'px';
    };
    recalc()
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
})(document, window);
// (function(baseFontSize) {
//     const _baseFontSize = baseFontSize || 100;
//     const ua = navigator.userAgent;
//     const matches = ua.match(/Android[\S\s]+AppleWebkit\/(\d{3})/i);
//     const isIos = navigator.appVersion.match(/(iphone|ipad|ipod)/gi);
//     let dpr = window.devicePixelRatio || 1;
//     if (!isIos && !(matches && matches[1] > 534)) {
//         // 如果非iOS, 非Android4.3以上, dpr设为1;
//         dpr = 1;
//     }
//     const scale = 1 / dpr;
//     const metaEl = document.querySelector('meta[name="viewport"]');
//     if (!metaEl) {
//         metaEl = document.createElement('meta');
//         metaEl.setAttribute('name', 'viewport');
//         window.document.head.appendChild(metaEl);
//     }
//     metaEl.setAttribute('content', 'width=device-width,user-scalable=no,initial-scale=' + scale + ',maximum-scale=' + scale + ',minimum-scale=' + scale);

//     document.documentElement.style.fontSize = document.documentElement.clientWidth / (1080 / _baseFontSize) + 'px';
// })();