// 基础 js 依赖库, 仅供 admin.js 和 application.js 共享使用
import 'bootstrap/dist/js/bootstrap';
import 'selectize';


import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// require("@fancyapps/fancybox"); // Import fancybox
require('@fancyapps/fancybox/dist/jquery.fancybox.min'); // Import fancybox

import('@rails/ujs').then( ujs => ujs.start() )
import('turbolinks').then( turbolinks => turbolinks.start() )
import('@rails/activestorage').then( activestorage => activestorage.start() )
import 'channels'
// import 'slick-carousel/slick/slick.min'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
import Swiper from 'swiper';
import ClipboardJS from './clipboard.min';
window.ClipboardJS = ClipboardJS;
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('images', true)
const imagePath = (name) => images(name, true)

// const slickCarouselInit = () => {
//   $('.slick-carousel').slick({
//     autoplay: true,
//     dots: true,
//     infinite: true,
//     speed: 500,
//     fade: true,
//     cssEase: 'linear',
//     responsive: true,
//     adaptiveHeight: true
//   });
// }


// $(document).on('turbolinks:load', function () {
  // slickCarouselInit();
// })

$(document).on('ready turbolinks:load', function() {
  $('[data-toggle="tooltip"]').tooltip()

  var swiper = new Swiper('.swiper-container', {
    direction: 'horizontal',
    // slidesPerGroup: 0,
    slidesPerView: 'auto',
    centeredSlides: true,
    // autoHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    lazy: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      // type: 'fraction',
      // renderFraction: function (currentClass, totalClass) {
      //   return '<span class="' + currentClass + '"></span>' +
      //     ' / ' +
      //     '<span class="' + totalClass + '"></span>';
      // }
    },
  });

  // Slick Carousel
  // slickCarouselInit();

  // FancyBox configrations
  $.fancybox.defaults.hash = false;
  $("[data-fancybox]").fancybox({
    loop: true,
    beforeShow: function () {
      // console.info(this); //Current object
      // console.info(this.element); //Group
      // console.info(this.group); // Element (link)
      // console.info(this.index); // Element index in the group
      if (!!swiper) {
        swiper.slideTo(this.index, false, false);
      }
    }
  });
});
